.header-background {
  animation: changeBackgroundColor 10s ease infinite;
}

header {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

/* BACKGROUND STYLES */

@keyframes changeBackgroundColor {
  0%,
  100% {
    background-color: #77bac5;
  }

  25% {
    background-color: #749bce;
  }

  50% {
    background-color: #7275cb;
  }

  75% {
    background-color: #5d76d3;
  }

  87.5% {
    background-color: #4a847a;
  }
}

/*------------ Picture Container ------------*/
.picture-container {
  justify-content: center;
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 2;
}

.picture-container img {
  width: 100%;
  height: auto;
  justify-content: center;
}

/*------------ Introduction Container ------------*/
.intro-text {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  justify-content: center;
  border-radius: 15px;
  padding: 15px;
  z-index: 2;
  line-height: 1.5;
  text-align: center;
  max-width: 800px;
  margin-top: 20px;
}

/*------------ Socials Container ------------*/
.social-icons {
  z-index: 3;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icons a {
  font-size: 60px;
  height: 60px;
  width: 100%;
}

.github,
.linkedin {
  background-color: #2d3748;
  color: white;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  outline: none;
  transition: transform 0.3s ease;
  border: 2px solid white;
}

.social-icons a:hover,
.social-icons a:focus,
.social-icons a:active {
  transform: scale(1.05);
}

/*------------ Picture Container ------------*/
@media (max-width: 640px) {
  .picture-container {
    width: 150px;
    height: 150px;
  }

  body.dark-mode .picture-container {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 700px) {
  .picture-container {
    width: 150px;
    height: 150px;
  }

  body.dark-mode .picture-container {
    width: 150px;
    height: 150px;
  }
}

/*------------ Introduction Container ------------*/
@media (max-width: 1024px) {
  .intro-text {
    font-size: 26px;
  }

  body.dark-mode .intro-text {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .intro-text {
    font-size: 22px;
  }

  p {
    font-size: 12px;
  }

  body.dark-mode .intro-text {
    font-size: 22px;
  }

  body.dark-mode p {
    font-size: 12px;
  }
}

@media (max-width: 640px) {
  .intro-text {
    font-size: 20px;
  }

  p {
    font-size: 12px;
  }

  body.dark-mode .intro-text {
    font-size: 20px;
  }

  body.dark-mode p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 700px) {
  .intro-text {
    font-size: 22px;
  }

  p {
    font-size: 12px;
  }

  body.dark-mode .intro-text {
    font-size: 22px;
  }

  body.dark-mode p {
    font-size: 12px;
  }
}

/*------------ Socials Container ------------*/
@media (max-width: 640px) {
  .github:hover,
  .github:focus,
  .github:active,
  .linkedin:hover,
  .linkedin:focus,
  .linkedin:active {
    transform: scale(1.05);
  }

  body.dark-mode .github:hover,
  body.dark-mode .github:focus,
  body.dark-mode .github:active,
  body.dark-mode .linkedin:hover,
  body.dark-mode .linkedin:focus,
  body.dark-mode .linkedin:active {
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 700px) {
  .github:hover,
  .github:focus,
  .github:active,
  .linkedin:hover,
  .linkedin:focus,
  .linkedin:active {
    transform: scale(1.05);
  }

  body.dark-mode .github:hover,
  body.dark-mode .github:focus,
  body.dark-mode .github:active,
  body.dark-mode .linkedin:hover,
  body.dark-mode .linkedin:focus,
  body.dark-mode .linkedin:active {
    transform: scale(1.05);
  }
}
