/*------------ Contact Section ------------*/
#contact {
  padding: 40px 20px;
  background-color: #ffffff;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeIn 1s forwards;
}

#contact.active {
  opacity: 1;
  transform: translateY(0);
}

.contact-section {
  position: relative;
  margin-bottom: 20px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeIn 1s forwards;
}

.contact-section:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.contact-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-header p {
  margin: 0;
  font-size: 16px;
  color: #4a5568;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form label {
  font-weight: bold;
  color: #333;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

.contact-form button {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*------------ Contact Dark Mode ------------*/
body.dark-mode #contact {
  background-color: #181818;
}

body.dark-mode #contact h2 {
  color: white;
}

body.dark-mode .contact-section {
  background-color: #2c2c2c;
  color: #fafafa;
  border: 1px solid #444;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

body.dark-mode .contact-section:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.663);
  transform: translateY(-5px);
}

body.dark-mode .contact-header,
body.dark-mode .contact-form label,
body.dark-mode .contact-form input,
body.dark-mode .contact-form textarea,
body.dark-mode .contact-form button {
  color: #fafafa;
}

body.dark-mode .contact-header .timeline-dot {
  background-color: #fafafa;
}

body.dark-mode .contact-form {
  display: flex;
  flex-direction: column;
}

body.dark-mode .contact-form label {
  margin-top: 10px;
  font-weight: bold;
  color: #fafafa;
}

body.dark-mode .contact-form input,
body.dark-mode .contact-form textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  font-size: 14px;
  background-color: #3c3c3c;
  color: #fafafa;
}

body.dark-mode .contact-form input::placeholder,
body.dark-mode .contact-form textarea::placeholder {
  color: #b0b0b0;
}

body.dark-mode .contact-form button {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

body.dark-mode .contact-form button:hover {
  background-color: #0056b3;
}

/*------------ Contact Section ------------*/
@media (max-width: 1024px) {
  .contact-form {
    gap: 10px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
  }

  .contact-form button {
    font-size: 14px;
    padding: 10px 15px;
  }

  body.dark-mode .contact-form {
    gap: 10px;
  }

  body.dark-mode .contact-form input,
  body.dark-mode .contact-form textarea {
    font-size: 14px;
  }

  body.dark-mode .contact-form button {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  .contact-form {
    gap: 10px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 14px;
  }

  .contact-form button {
    font-size: 14px;
    padding: 10px 15px;
  }

  body.dark-mode .contact-form {
    gap: 10px;
  }

  body.dark-mode .contact-form input,
  body.dark-mode .contact-form textarea {
    font-size: 14px;
  }

  body.dark-mode .contact-form button {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media (max-width: 640px) {
  .contact-form {
    gap: 8px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 12px;
  }

  .contact-form button {
    font-size: 12px;
    padding: 8px 12px;
  }

  body.dark-mode .contact-form {
    gap: 8px;
  }

  body.dark-mode .contact-form input,
  body.dark-mode .contact-form textarea {
    font-size: 12px;
  }

  body.dark-mode .contact-form button {
    font-size: 12px;
    padding: 8px 12px;
  }
}
