/*------------ Experience Section ------------*/
#experience {
  padding: 40px 20px;
  background-color: #ffffff;
  transition: opacity 1s ease, transform 1s ease;
}

.timeline {
  position: relative;
  margin: 0 auto;
  padding: 20px 0;
  width: 90%;
}

.timeline-center-line {
  position: absolute;
  left: 50%;
  width: 4px;
  background-color: #9e9e9e;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
}

.experience-container {
  position: relative;
  width: 45%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.experience-container.left {
  left: calc(50% - 50% + 30px);
  text-align: left;
}

.experience-container.right {
  left: calc(50% + 20px);
  text-align: left;
}

.experience-container.left::before {
  content: "";
  position: absolute;
  top: 20px;
  right: -10px;
  margin-bottom: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #c2c1c1;
}

.experience-container.right::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #c2c1c1 transparent transparent;
}

.experience-container:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.experience-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.date {
  font-size: 18px;
  color: #000000;
  font-style: italic;
  font-weight: 600;
}

.experience-details {
  text-align: center;
}

.experience-details .company {
  text-align: center;
  margin-bottom: 10px;
}

.experience-details .role {
  text-align: center;
  margin-top: 0;
}

.experience-details h6 {
  margin-top: 10px;
  font-size: 14px;
  color: #000000;
  text-align: left;
}

/*------------ Experience Dark Mode ------------*/
body.dark-mode {
  background-color: #181818;
}

body.dark-mode .timeline-center-line {
  position: absolute;
  left: 50%;
  width: 4px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
}

body.dark-mode #experience {
  background-color: #181818;
}

body.dark-mode #experience h2 {
  color: white;
}

body.dark-mode .experience-container {
  background-color: #2c2c2c;
  color: #fafafa;
  border: 1px solid #444;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
}

body.dark-mode .experience-container:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.561);
  transform: translateY(-5px);
}

body.dark-mode .experience-header p,
body.dark-mode .experience-details h4,
body.dark-mode .experience-details p,
body.dark-mode .experience-details h6 {
  color: #fafafa;
}

body.dark-mode .experience-container.right::before {
  border-color: transparent #c2c1c1 transparent transparent;
}

body.dark-mode .date {
  color: white;
}

/*------------ Responsive Design ------------*/
@media (max-width: 768px) {
  .experience-container {
    width: 100%;
    margin-bottom: 20px;
    transform: none;
  }

  .experience-container.left,
  .experience-container.right {
    left: 0;
    text-align: left;
  }

  .experience-container::before {
    display: none;
  }

  .timeline-center-line {
    display: none;
  }

  .date-container {
    position: static;
    text-align: left;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  #experience {
    padding: 20px 10px;
  }

  .experience-header p {
    font-size: 12px;
  }

  .experience-details h4 {
    font-size: 16px;
  }

  .experience-details h6 {
    font-size: 12px;
  }

  .timeline {
    width: 100%;
    padding: 0;
  }

  .experience-container {
    width: 100%;
    margin-left: 0;
  }

  .date-container {
    position: static;
    text-align: left;
    margin-bottom: 10px;
  }
}

@media (max-width: 475px) {
  #experience {
    padding: 20px 5px;
  }

  .experience-header p {
    font-size: 11px;
  }

  .experience-details h4 {
    font-size: 15px;
  }

  .experience-details h6 {
    font-size: 11px;
  }

  .timeline {
    width: 100%;
    padding: 0;
  }

  .experience-container {
    width: 100%;
    margin-left: 0;
  }

  .date-container {
    position: static;
    text-align: left;
    margin-bottom: 10px;
  }
}
