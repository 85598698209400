/*------------ Education Section ------------*/
#education {
  padding: 20px;
  background-color: #ffffff;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeIn 1s forwards;
}

#education.active {
  opacity: 1;
  transform: translateY(0);
}

.education-section {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeIn 1s forwards;
}

.education-section:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.education-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.education-header .timeline-dot {
  width: 12px;
  height: 12px;
  background-color: #2d3748;
  border-radius: 50%;
  margin-right: 15px;
}

.education-header p {
  margin: 0;
  font-size: 14px;
  color: #4a5568;
}

.seal-degree-univ-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.education-seal {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.degree-univ-text {
  display: flex;
  flex-direction: column;
}

.degree-univ-text h4 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

.degree-univ-text p {
  margin-top: 0;
  font-size: 16px;
}

.education-details {
  display: flex;
  flex-direction: column;
}

.education-section h4 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.education-section p {
  margin: 5px 0;
}

.education-section h6 {
  margin-top: 10px;
  font-size: 14px;
  color: #718096;
}

/*------------ Education Dark Mode ------------*/
body.dark-mode #education {
  background-color: #181818;
}

body.dark-mode #education h2 {
  color: white;
}

body.dark-mode .education-section {
  background-color: #2c2c2c;
  color: #fafafa;
  border: 1px solid #444;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

body.dark-mode .education-section:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.485);
  transform: translateY(-5px);
}

body.dark-mode .education-header p,
body.dark-mode .education-section h4,
body.dark-mode .education-section p,
body.dark-mode .education-section h6 {
  color: #fafafa;
}

body.dark-mode .timeline-dot {
  background-color: #fafafa;
}

/*------------ Education Section ------------*/
@media (max-width: 1024px) {
  .seal-degree-univ-section {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .education-seal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .degree-univ-text {
    align-items: center;
  }

  body.dark-mode .seal-degree-univ-section {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-bottom: 20px;
  }

  body.dark-mode .education-seal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  body.dark-mode .degree-univ-text {
    align-items: center;
  }
}

@media (max-width: 768px) {
  .seal-degree-univ-section {
    flex-direction: column;
    align-items: center;
  }

  .education-seal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .degree-univ-text {
    align-items: center;
  }

  body.dark-mode .seal-degree-univ-section {
    flex-direction: column;
    align-items: center;
  }

  body.dark-mode .education-seal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  body.dark-mode .degree-univ-text {
    align-items: center;
  }
}

@media (max-width: 640px) {
  .seal-degree-univ-section {
    flex-direction: column;
    align-items: center;
  }

  .education-seal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .degree-univ-text {
    align-items: center;
  }

  .education-section h4,
  .education-section p,
  .education-section h6 {
    text-align: center;
  }

  body.dark-mode .seal-degree-univ-section {
    flex-direction: column;
    align-items: center;
  }

  body.dark-mode .education-seal {
    margin-right: 0;
    margin-bottom: 20px;
  }

  body.dark-mode .degree-univ-text {
    align-items: center;
  }

  body.dark-mode .education-section h4,
  body.dark-mode .education-section p,
  body.dark-mode .education-section h6 {
    text-align: center;
  }
}
