/*------------ Nav Bar Container ------------*/
.active-link {
  background-color: #ffffff;
  color: #000000;
  padding: 2px 10px;
}

nav {
  z-index: 5;
  position: fixed;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 65%;
  padding: 5px 15px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  background-color: #333333;
  font-weight: 900;
}

nav.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

nav > div {
  display: flex;
  justify-content: space-between;
}

nav h4,
nav a {
  border-radius: 10px;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

nav a:hover,
nav h4:hover {
  color: #d6d3d3;
  font-weight: bold;
}

.circular-button {
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
}

.circular-button:hover {
  background-color: #0056b3;
}

.fixed-bottom-left {
  position: fixed;
  bottom: 20px;
  left: 20px;
}

/*------------ Nav Bar Dark Mode ------------*/
body.dark-mode nav {
  background-color: #000000;
}

body.dark-mode .active-link {
  background-color: #faf8f8;
  color: #000000;
}

body.dark-mode nav h4,
body.dark-mode nav a {
  color: #fafafa;
}

body.dark-mode hr {
  margin: 0 0;
  border: none;
  border-top: 1px solid #ffffff;
}

/*------------ Navbar Media Queries ------------*/
@media (max-width: 1024px) {
  nav {
    width: 80%;
  }

  nav h4 {
    font-size: 14px;
  }

  body.dark-mode nav {
    width: 80%;
  }

  body.dark-mode nav h4 {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  nav {
    width: 90%;
  }

  nav h4 {
    font-size: 12px;
  }

  body.dark-mode nav {
    width: 90%;
  }

  body.dark-mode nav h4 {
    font-size: 12px;
  }
}

@media (max-width: 640px) {
  .active-link {
    padding: 4px;
  }

  nav {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  nav > div {
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  nav h4 {
    font-size: 12px;
    text-align: center;
    padding: 5px 0;
    flex: 1;
    white-space: nowrap;
  }

  .circular-button {
    margin-left: 10px;
  }

  body.dark-mode nav {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  body.dark-mode nav > div {
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  body.dark-mode nav h4 {
    font-size: 12px;
    text-align: center;
    padding: 5px 0;
    flex: 1;
    white-space: nowrap;
  }

  body.dark-mode .circular-button {
    margin-left: 10px;
  }
}

@media (max-width: 426px) {
  .navbar-container {
    width: 90%;
    padding: 10px;
  }

  nav h4 {
    font-size: 10px;
    padding: 5px 0;
  }

  .circular-button {
    margin-left: 10px;
  }

  body.dark-mode .navbar-container {
    width: 90%;
    padding: 10px;
  }

  body.dark-mode nav h4 {
    font-size: 10px;
    padding: 5px 0;
  }

  body.dark-mode .circular-button {
    margin-left: 10px;
  }
}
