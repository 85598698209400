/*------------ Projects Section ------------*/
#projects {
  padding: 40px 20px;
  background-color: #ffffff;
  transition: opacity 1s ease, transform 1s ease;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

#my-projects {
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
}

.project-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.project-container:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.project-description {
  flex: 1;
  padding-right: 20px;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.project-made-with {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.project-made-with span {
  margin-right: 10px;
  font-weight: bold;
}

.project-icons {
  display: flex;
  gap: 10px;
  position: relative;
}

.project-icons iconify-icon {
  font-size: 24px;
  transition: transform 0.3s ease, color 0.3s ease;
  position: relative;
}

.project-icons iconify-icon:hover {
  transform: scale(1.2);
  color: #007bff; /* You can change this color to any desired hover color */
}

.project-icons iconify-icon::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  font-size: 0.75rem;
}

.project-icons iconify-icon:hover::after {
  opacity: 1;
}

.project-text {
  margin: 10px 0;
}

.project-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.project-button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.project-button:hover {
  background-color: #0056b3;
}

.project-button.github {
  background-color: #24292e;
}

.project-button.github:hover {
  background-color: #4c4d4f;
}

.project-button.icon {
  margin-right: 5px;
}

/* Animations */
.fade-in {
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in.active {
  opacity: 1;
  animation: fadeInSlide 0.6s forwards;
}

.drop-in {
  opacity: 0;
  transform: translateY(-50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.drop-in.active {
  opacity: 1;
  transform: translateY(0);
}

@keyframes dropIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.project-image {
  width: 40%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-image:hover {
  transform: rotate(8deg);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/*------------ Projects Dark Mode ------------*/
body.dark-mode #projects {
  background-color: #181818;
  overflow-x: hidden;
}

body.dark-mode #my-projects {
  color: white;
}

body.dark-mode .project-container {
  background-color: #2c2c2c;
  color: #fafafa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
}

body.dark-mode .project-container:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.561);
}

body.dark-mode .project-button {
  background-color: #555555;
}

body.dark-mode .project-button:hover {
  background-color: #707070;
}

body.dark-mode .project-button.github {
  background-color: #333333;
}

body.dark-mode .project-button.github:hover {
  background-color: #4b4a4a;
}

/*------------ Responsive Design ------------*/
@media (max-width: 1024px) {
  .project-container {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .project-description {
    padding-right: 0;
    padding-bottom: 20px;
  }

  .project-image {
    width: 100%;
    margin-bottom: 20px;
  }

  body.dark-mode .project-container {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  body.dark-mode .project-description {
    padding-right: 0;
    padding-bottom: 20px;
  }

  body.dark-mode .project-image {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .project-title {
    font-size: 1.25rem;
  }

  .project-buttons {
    flex-direction: column;
  }

  .project-button {
    width: 100%;
    justify-content: center;
  }

  body.dark-mode .project-title {
    font-size: 1.25rem;
  }

  body.dark-mode .project-buttons {
    flex-direction: column;
  }

  body.dark-mode .project-button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  .project-container {
    flex-direction: column;
    align-items: center;
    max-width: auto;
  }

  .project-description {
    padding-right: 0;
    padding-bottom: 15px;
  }

  .project-text {
    font-size: 13px;
  }

  .project-buttons {
    flex-direction: column;
  }

  .project-button {
    width: 100%;
    justify-content: center;
  }

  .project-image {
    width: 100%;
    margin-bottom: 15px;
  }
}
