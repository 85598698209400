/*------------ Skills Section ------------*/
#skills {
  background-color: white;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: transparent;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
  font-size: 65px;
}

.skills-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(50px); /* Initial position for bounce */
}

.skills-icon-container.active {
  opacity: 1;
  animation: bounceIn 0.6s forwards ease-in-out;
}

.skills-icon-container:hover {
  background-color: rgba(198, 195, 195, 0.5) !important;
  transform: translateY(-10px) !important;
}

.skills-icon {
  margin-bottom: 10px;
  font-size: 40px;
  transition: color 0.3s;
}

.skills-label {
  margin-top: 8px;
  font-size: 18px;
  text-align: center;
  color: #333;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 1;
    transform: translateY(-20px);
  }
  70% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

/*------------ Skills Dark Mode ------------*/
body.dark-mode #skills {
  background-color: #181818;
}

body.dark-mode #skills h2 {
  color: white;
}

body.dark-mode .skills-container {
  background-color: transparent; /* Transparent container */
  color: #fafafa;
  padding: 20px;
  border-radius: 10px;
}

body.dark-mode .skills-icon-container {
  background-color: #333;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

body.dark-mode .skills-icon-container:hover {
  background-color: rgba(
    68,
    68,
    68,
    0.8
  ); /* Semi-transparent background on hover */
  transform: translateY(-10px);
}

body.dark-mode .skills-icon {
  color: #fafafa;
  font-size: 40px;
  transition: color 0.3s;
}

body.dark-mode .skills-label {
  color: #fafafa;
  margin-top: 8px;
  font-size: 18px;
  text-align: center;
}

/*------------ Skills Section ------------*/
@media (max-width: 1024px) {
  .skills-icon-container {
    padding: 5px;
  }

  .skills-icon {
    font-size: 35px;
  }

  .skills-label {
    font-size: 16px;
  }

  body.dark-mode .skills-icon-container {
    padding: 5px;
  }

  body.dark-mode .skills-icon {
    font-size: 35px;
  }

  body.dark-mode .skills-label {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .skills-icon {
    font-size: 30px;
  }

  .skills-label {
    font-size: 14px;
  }

  body.dark-mode .skills-icon {
    font-size: 30px;
  }

  body.dark-mode .skills-label {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  .skills-icon {
    font-size: 25px;
  }

  .skills-label {
    font-size: 12px;
  }

  body.dark-mode .skills-icon {
    font-size: 25px;
  }

  body.dark-mode .skills-label {
    font-size: 12px;
  }
}
