@tailwind base;
@tailwind components;
@tailwind utilities;

/*------------ Universal Styles ------------*/
* {
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body.dark-mode {
  background-color: #181818;
}

body {
  padding: 0;
  margin: 0;
}

header,
section {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
}

hr {
  border: none;
  border-top: 1px solid #ddd;
}

p {
  font-size: 14px;
  text-align: center;
  padding-top: 40px;
}

h1,
h2 {
  z-index: 3;
}

h2 {
  margin-bottom: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
